<template>
  <div class="custom-popup">
    <vs-popup title="" class="custom-popup" :active.sync="isPopupActive" style="color: rgb(255, 255, 255)" background-color="rgba(255,255,255,.6)">
      <div class="popup-header flex items-center mt-10">
        <vs-icon icon="error_outline" size="3rem" class="mr-3 text-red"></vs-icon>
        <h3 class="font-bold">Delete this {{ deleteWhat }}?</h3>
      </div>
      <br />
      <p class="ml-16 text-gray-font">Are you sure you want to permanently delete this {{ deleteWhat }}? You can’t undo this action.</p>
      <br />
      <div class="error-message-container ml-20">
        <vs-icon icon="warning_amber" size="2rem" class="mr-3"></vs-icon>
        <div>
          <span>WARNING</span>
          <br />
          <p>{{ warningContent }}</p>
        </div>
      </div>

      <div class="actions mt-12 text-right w-full">
        <vs-button class="btn btn-primary px-5 btn-delete" size="large" @click="handleClick('delete')" v-round>{{ buttonText }}</vs-button>
        <a class="btn ml-5 mid-blue" v-round @click="handleClick('cancel')"><u>Cancel</u></a>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { AlertCircleIcon } from "vue-feather-icons";
export default {
  name: "DeletePopup",
  props: {
    popupActive: { required: true, type: Boolean },
    warningContent: { required: false, type: String },
    additionalMsg: { required: false, type: String},
    deleteWhat: { required: false, type: String },
    buttonText: { required: false, type: String },
    found: { required: false, type: Boolean },
  },
  components: { AlertCircleIcon },
  computed: {
    isPopupActive: {
      // getter
      get: function () {
        return this.popupActive;
      },
      // setter
      set: function (newValue) {
        
        this.handleClick("close");
      },
    },
  },
  methods: {
    handleClick(value) {
      this.$emit("handleDeleteClick", value);
      return;
    },
  },
};
</script>